import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Loader, Modal, Select } from 'semantic-ui-react';
import CategorySegment from '../../Segment/CategorySegment';
import CourseSegment from '../../Segment/CourseSegment';
import './style.css';

const UserProgressModal = ({ progressUser, closeModal }) => {
  const [userProgress, setUserProgress] = useState({
    learningPath: {},
    courses: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const { t } = useTranslation();

  const getUserProgress = () => {
    let url = `/api/v1/users/${progressUser.id}/progress`;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        setUserProgress(resp);
        if (resp.template) {
          setSelectedTemplate(resp.template.id);
        }
        setIsLoading(false);
        setIsChangeOpen(false);
      });
  };

  useEffect(() => {
    getUserProgress();
    fetch(`/api/v1/template/list`)
      .then((response) => response.json())
      .then((response) => {
        setTemplates(response);
      });
  }, [progressUser]);

  const updateTemplate = () => {
    setIsLoading(true);
    const requestOptions = {
      method: 'POST',
    };
    fetch(
      `/api/v1/template/${selectedTemplate}/user/${progressUser.id}`,
      requestOptions,
    )
      .then(() => getUserProgress())
      .catch((a) => console.log(a));
  };

  let customCourses = [];
  let skbyCourses = [];
  userProgress.courses.forEach((category) => {
    if (category.isInternal) {
      customCourses = [...customCourses, ...category.courses];
    } else {
      skbyCourses = [...skbyCourses, category];
    }
  });

  return (
    <Modal dimmer='inverted' open={!!progressUser}>
      <Modal.Header>
        {progressUser.firstName} {progressUser.lastName} progress
      </Modal.Header>
      <Modal.Content>
        <div className='modal__content'>
          {isLoading ? (
            <Loader active inline='centered'>
              {t('admin.Loading')}
            </Loader>
          ) : (
            <div>
              {isChangeOpen && templates ? (
                <h4 className='user-progress_title'>
                  <Select
                    placeholder='Õpirada'
                    options={templates.map((template) => ({
                      key: template.id,
                      value: template.id,
                      text: template.name,
                    }))}
                    onChange={(a, { value }) => setSelectedTemplate(value)}
                    value={selectedTemplate}
                  />
                  <Button
                    color='blue'
                    size='mini'
                    disabled={selectedTemplate === userProgress.template.id}
                    onClick={updateTemplate}
                  >
                    Valmis
                  </Button>
                </h4>
              ) : (
                <Fragment>
                  <h4 className='user-progress_title'>
                    {`Õpirada: ${
                      !!userProgress.template
                        ? userProgress.template.name
                        : 'määramata'
                    }
                  `}
                  </h4>
                </Fragment>
              )}
              {userProgress.courses.length > 0 && (
                <Divider horizontal>Moodulid</Divider>
              )}
              {userProgress.courses.map((category) => {
                if (category.isInternal || category.courses.length === 1) {
                  return category.courses.map((course) => (
                    <CourseSegment
                      key={`course-${course.id}`}
                      user={progressUser}
                      course={course}
                    />
                  ));
                }
                return (
                  <CategorySegment
                    key={`category-${category.id}`}
                    user={progressUser}
                    category={category}
                  />
                );
              })}
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Sulge</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserProgressModal;
